import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Yeti_Home.vue";
import About from "../views/Yeti_About.vue";
import Activities from "../views/Yeti_Activities.vue";
import Contact from "../views/Yeti_Contact.vue";
import Photo from "../views/Yeti_Photo.vue";
import Tarifs from "../views/Yeti_Tarifs.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activities,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/photo",
    name: "Photo",
    component: Photo,
  },
  {
    path: "/tarifs",
    name: "Tarifs",
    component: Tarifs,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
