

























































































































import Vue from "vue";

export default Vue.extend({
  name: "yeti_About",
  components: {},
  data: () => ({}),
  computed: {
    lang: function () {
      return this.$route.query?.lang || "fr";
    },
  },
});
