



























































































import Vue from "vue";
import router from "./router";

export default Vue.extend({
  name: "App",

  components: {},

  data: () => ({
    pages: [
      {
        title: {
          fr: "Accueil",
          en: "Main Page",
          it: "Pagina Principale",
          dk: "Forside",
        },
        url: "/",
      },
      {
        title: {
          fr: "Le Yéti",
          en: "The Yéti",
          it: "Lo Yéti",
          dk: "Yéti'en",
        },
        url: "/about",
      },
      {
        title: {
          fr: "Photos et Vidéos",
          en: "Photos & Videos",
          it: "Foto e Video",
          dk: "Billeder og video",
        },
        url: "/photo",
      },
      {
        title: {
          fr: "Activités ",
          en: "Activities ",
          it: "Attività ",
          dk: "Aktiviteter",
        },
        url: "/activities",
      },
      {
        title: {
          fr: "Tarifs et Réservations",
          en: "Tarifs & Reservations",
          it: "Tariffe e Reservazioni",
          dk: "Priser og booking",
        },
        url: "/tarifs",
      },
      {
        title: {
          fr: "Nous contacter",
          en: "Contact Us",
          it: "Contattateci",
          dk: "Kontakt os",
        },
        url: "/contact",
      },
    ],
    drawer: false,
    language: "fr",
  }),

  methods: {
    changeLanguage(lang: string) {
      this.language = lang;
      router.replace({ path: `${this.$route.path}?lang=${lang}` });
    },
  },

  computed: {
    lang: function () {
      return this.$route.query?.lang || "fr";
    },
  },
});
