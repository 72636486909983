



























































































import Vue from "vue";
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

export default Vue.extend({
  name: "Home",
  components: {},
  data: () => ({
    buttonText: {
      fr: "Reservez",
      en: "Book",
      it: "Prenota",
      dk: "Booking",
    },
    isVisible: false,
  }),
  computed: {
    lang: function () {
      return this.$route.query?.lang || "fr";
    },
  },
  methods: {
    changeVisibility: function(isVisible: boolean, entry: any) {
      this.isVisible = isVisible;
      console.log('changing visibility of ', entry);
    }
  }
});
